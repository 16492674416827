//Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
//Copyright (C) 2024 HTWKalender support@htwkalender.de

//This program is free software: you can redistribute it and/or modify
//it under the terms of the GNU Affero General Public License as published by
//the Free Software Foundation, either version 3 of the License, or
//(at your option) any later version.

//This program is distributed in the hope that it will be useful,
//but WITHOUT ANY WARRANTY; without even the implied warranty of
//MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
//GNU Affero General Public License for more details.

//You should have received a copy of the GNU Affero General Public License
//along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { createI18n } from "vue-i18n";
import en from "./translations/en.json";
import de from "./translations/de.json";
import localeStore from "../store/localeStore.ts";

// Private instance of VueI18n object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let _i18n: any;
// Initializer
function setup() {
  _i18n = createI18n({
    legacy: false,
    locale: localeStore().locale,
    fallbackLocale: "de",
    messages: {
      en,
      de,
    },
  });
  return _i18n;
}

// Sets the active locale.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setLocale(newLocale: any) {
  _i18n.global.locale = newLocale;
  setDocumentAttributesFor(newLocale);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setDocumentAttributesFor(locale: any) {
  const htmlElement = document.querySelector("html");

  htmlElement?.setAttribute("lang", locale);
}

// Public interface
export default {
  // Expose the VueI18n instance via a getter
  get vueI18n() {
    return _i18n;
  },
  setup,
  setLocale,
};
