<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import { computed, SetupContext, useSlots } from "vue";

defineProps<{
  hideContent: boolean;
  headline: string;
  subTitle?: string;
  icon?: string;
  button?: {
    label: string;
    icon: string;
    disabled: boolean;
    onClick: () => void;
  };
  upperButton?: {
    label: string;
    icon: string;
    disabled: boolean;
    onClick: () => void;
  };
  lowerButton?: {
    label: string;
    icon: string;
    disabled: boolean;
    onClick: () => void;
  };
}>();

const slots: SetupContext["slots"] = useSlots();
const hasSlot = (name: string) => {
  return !!slots[name];
};
const hasContent = computed(() => {
  return hasSlot("content");
});
</script>

<template>
  <div class="flex flex-column align-items-center mt-0">
    <div
      class="flex align-items-center justify-content-center gap-3 mx-2 mb-4 transition-rolldown"
      :class="{ 'md:mt-8': hideContent }"
    >
      <h1 class="text-4xl">
        {{ headline }}
      </h1>
      <i v-if="icon" :class="icon" style="font-size: 2rem"></i>
    </div>
    <div v-if="subTitle" class="flex justify-content-center">
      <p class="subtitle text-2xl m-2">{{ subTitle }}</p>
    </div>
    <div class="flex flex-wrap mx-0 gap-2 my-4 w-full lg:w-8">
      <slot name="selection" flex-specs="flex-1 m-0"></slot>
    </div>
    <div
      v-if="button"
      class="flex flex-wrap m-0 mb-3 gap-2 w-full lg:w-8 align-items-center justify-content-end"
    >
      <Button
        :disabled="button.disabled"
        class="col-12 md:col-4"
        :icon="button.icon"
        :label="button.label"
        @click="button.onClick()"
      />
    </div>
    <div
      v-if="hasContent"
      :class="[
        hideContent
          ? ['opacity-0', 'pointer-events-none', 'h-1rem', 'overflow-hidden']
          : [
              'opacity-100',
              'transition-all',
              'transition-duration-500',
              'transition-ease-in-out',
            ],
        'w-full',
        'lg:w-8',
      ]"
    >
      <slot name="content"></slot>
      <div
        v-if="button"
        class="flex flex-wrap my-3 gap-2 align-items-center justify-content-end"
      >
        <Button
          :disabled="button.disabled"
          class="col-12 md:col-4"
          :icon="button.icon"
          :label="button.label"
          @click="button.onClick()"
        />
      </div>
      <div
        v-if="lowerButton"
        class="flex flex-wrap my-3 gap-2 align-items-center justify-content-end"
      >
        <Button
          :disabled="lowerButton.disabled"
          class="col-12 md:col-4"
          :icon="lowerButton.icon"
          :label="lowerButton.label"
          @click="lowerButton.onClick()"
        />
      </div>
      <div
        v-if="button"
        class="flex flex-wrap my-3 gap-2 align-items-center justify-content-end"
      >
      </div>
    </div>
  </div>
</template>

<style scoped>
.transition-rolldown {
  transition: margin-top 0.5s ease-in-out;
}
.subtitle {
  font-weight: 100;
  font-size: 1.5rem;
}
</style>
